import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7a4f3206 = () => interopDefault(import('../pages/403/index.vue' /* webpackChunkName: "pages/403/index" */))
const _54a909ec = () => interopDefault(import('../pages/adminDashboard/index.vue' /* webpackChunkName: "pages/adminDashboard/index" */))
const _d4d8b8c8 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _f9bf47ba = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _c6225244 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6755c400 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _3023c646 = () => interopDefault(import('../pages/files/index.vue' /* webpackChunkName: "pages/files/index" */))
const _791eed85 = () => interopDefault(import('../pages/forgotPassword/index.vue' /* webpackChunkName: "pages/forgotPassword/index" */))
const _15d54d28 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _e0007360 = () => interopDefault(import('../pages/ledger/index.vue' /* webpackChunkName: "pages/ledger/index" */))
const _5cc30509 = () => interopDefault(import('../pages/locationOrganizer/index.vue' /* webpackChunkName: "pages/locationOrganizer/index" */))
const _243af4b8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _8d6c65a4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _50465773 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _3e845e68 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _5d3152e4 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _6eeb6964 = () => interopDefault(import('../pages/scan/index.vue' /* webpackChunkName: "pages/scan/index" */))
const _8b9a785c = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _7f832cac = () => interopDefault(import('../pages/account/settings/index.vue' /* webpackChunkName: "pages/account/settings/index" */))
const _b4c204b2 = () => interopDefault(import('../pages/adminDashboard/custom-invoice.vue' /* webpackChunkName: "pages/adminDashboard/custom-invoice" */))
const _032db7b6 = () => interopDefault(import('../pages/adminDashboard/email.vue' /* webpackChunkName: "pages/adminDashboard/email" */))
const _1f7bd693 = () => interopDefault(import('../pages/adminDashboard/flex-credit-memos.vue' /* webpackChunkName: "pages/adminDashboard/flex-credit-memos" */))
const _07da5408 = () => interopDefault(import('../pages/adminDashboard/payout.vue' /* webpackChunkName: "pages/adminDashboard/payout" */))
const _db250582 = () => interopDefault(import('../pages/adminDashboard/presale-invoices.vue' /* webpackChunkName: "pages/adminDashboard/presale-invoices" */))
const _de646838 = () => interopDefault(import('../pages/adminDashboard/rolesAndPermissions.vue' /* webpackChunkName: "pages/adminDashboard/rolesAndPermissions" */))
const _5a3e52ec = () => interopDefault(import('../pages/dashboard/editor.vue' /* webpackChunkName: "pages/dashboard/editor" */))
const _3d3d59f1 = () => interopDefault(import('../pages/dashboard/inProgress.vue' /* webpackChunkName: "pages/dashboard/inProgress" */))
const _6a777610 = () => interopDefault(import('../pages/dashboard/inProgress1.vue' /* webpackChunkName: "pages/dashboard/inProgress1" */))
const _6a858d91 = () => interopDefault(import('../pages/dashboard/inProgress2.vue' /* webpackChunkName: "pages/dashboard/inProgress2" */))
const _3934d070 = () => interopDefault(import('../pages/events/archive.vue' /* webpackChunkName: "pages/events/archive" */))
const _33fa2684 = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _1e3c79a7 = () => interopDefault(import('../pages/events/current.vue' /* webpackChunkName: "pages/events/current" */))
const _0f619b42 = () => interopDefault(import('../pages/events/history.vue' /* webpackChunkName: "pages/events/history" */))
const _87a185d6 = () => interopDefault(import('../pages/events/media/index.vue' /* webpackChunkName: "pages/events/media/index" */))
const _b9944896 = () => interopDefault(import('../pages/forgotPassword/sent.vue' /* webpackChunkName: "pages/forgotPassword/sent" */))
const _ea5025f4 = () => interopDefault(import('../pages/login/create.vue' /* webpackChunkName: "pages/login/create" */))
const _dd9dd96a = () => interopDefault(import('../pages/media/categories.vue' /* webpackChunkName: "pages/media/categories" */))
const _32fe8e98 = () => interopDefault(import('../pages/media/files.vue' /* webpackChunkName: "pages/media/files" */))
const _6aab8e44 = () => interopDefault(import('../pages/orders/all/index.vue' /* webpackChunkName: "pages/orders/all/index" */))
const _4b07f397 = () => interopDefault(import('../pages/subscriptions/all/index.vue' /* webpackChunkName: "pages/subscriptions/all/index" */))
const _3f7a862a = () => interopDefault(import('../pages/ticket/create.vue' /* webpackChunkName: "pages/ticket/create" */))
const _2015833d = () => interopDefault(import('../pages/events/media/editor/index.vue' /* webpackChunkName: "pages/events/media/editor/index" */))
const _6ad8654a = () => interopDefault(import('../pages/orders/events/upcomming.vue' /* webpackChunkName: "pages/orders/events/upcomming" */))
const _43ea4082 = () => interopDefault(import('../pages/subscriptions/events/upcomming.vue' /* webpackChunkName: "pages/subscriptions/events/upcomming" */))
const _2d15b942 = () => interopDefault(import('../pages/subscriptions/view/cancel/_id.vue' /* webpackChunkName: "pages/subscriptions/view/cancel/_id" */))
const _492b8630 = () => interopDefault(import('../pages/subscriptions/payment/stripe/_orderIncrementId/_pi.vue' /* webpackChunkName: "pages/subscriptions/payment/stripe/_orderIncrementId/_pi" */))
const _54900cf7 = () => interopDefault(import('../pages/events/edit/_id.vue' /* webpackChunkName: "pages/events/edit/_id" */))
const _a909e2dc = () => interopDefault(import('../pages/events/view/_id.vue' /* webpackChunkName: "pages/events/view/_id" */))
const _6825cb74 = () => interopDefault(import('../pages/login/public/_token.vue' /* webpackChunkName: "pages/login/public/_token" */))
const _c30adef2 = () => interopDefault(import('../pages/orders/invoices/_id.vue' /* webpackChunkName: "pages/orders/invoices/_id" */))
const _2058576e = () => interopDefault(import('../pages/orders/payment/_id/index.vue' /* webpackChunkName: "pages/orders/payment/_id/index" */))
const _a14c4f74 = () => interopDefault(import('../pages/orders/view/_id.vue' /* webpackChunkName: "pages/orders/view/_id" */))
const _8c42a1a4 = () => interopDefault(import('../pages/subscriptions/invoices/_id.vue' /* webpackChunkName: "pages/subscriptions/invoices/_id" */))
const _bcfce9c4 = () => interopDefault(import('../pages/subscriptions/item/_id/index.vue' /* webpackChunkName: "pages/subscriptions/item/_id/index" */))
const _5c5f6db2 = () => interopDefault(import('../pages/subscriptions/payment/_id/index.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/index" */))
const _716d626d = () => interopDefault(import('../pages/subscriptions/view/_id.vue' /* webpackChunkName: "pages/subscriptions/view/_id" */))
const _7a6155eb = () => interopDefault(import('../pages/account/settings/_field/_token/index.vue' /* webpackChunkName: "pages/account/settings/_field/_token/index" */))
const _57203286 = () => interopDefault(import('../pages/customer/events/_eventId/_orderItemId.vue' /* webpackChunkName: "pages/customer/events/_eventId/_orderItemId" */))
const _da0ffd12 = () => interopDefault(import('../pages/orders/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/orders/payment/_id/_payment_method/_status" */))
const _7ad656f0 = () => interopDefault(import('../pages/subscriptions/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/_payment_method/_status" */))
const _2d9afc5e = () => interopDefault(import('../pages/autologin/_id.vue' /* webpackChunkName: "pages/autologin/_id" */))
const _61ef696e = () => interopDefault(import('../pages/check-in/_locationId.vue' /* webpackChunkName: "pages/check-in/_locationId" */))
const _8482e988 = () => interopDefault(import('../pages/files/_orderItemId/index.vue' /* webpackChunkName: "pages/files/_orderItemId/index" */))
const _fe311b48 = () => interopDefault(import('../pages/Link/_hash.vue' /* webpackChunkName: "pages/Link/_hash" */))
const _3c1aa1c9 = () => interopDefault(import('../pages/locationOrganizer/_locationId/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/index" */))
const _2e298bf8 = () => interopDefault(import('../pages/login/_token.vue' /* webpackChunkName: "pages/login/_token" */))
const _047080de = () => interopDefault(import('../pages/media/_id/index.vue' /* webpackChunkName: "pages/media/_id/index" */))
const _6bfade62 = () => interopDefault(import('../pages/ticket/_customerMembershipId.vue' /* webpackChunkName: "pages/ticket/_customerMembershipId" */))
const _cde7794a = () => interopDefault(import('../pages/locationOrganizer/_locationId/all/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/all/index" */))
const _19ad373a = () => interopDefault(import('../pages/locationOrganizer/_locationId/ticket-stats/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/ticket-stats/index" */))
const _c1dcb4ae = () => interopDefault(import('../pages/scan/_hash/public.vue' /* webpackChunkName: "pages/scan/_hash/public" */))
const _792d4084 = () => interopDefault(import('../pages/locationOrganizer/_locationId/event/_eventId.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/event/_eventId" */))
const _0aecae6a = () => interopDefault(import('../pages/files/_orderItemId/_eventDateId.vue' /* webpackChunkName: "pages/files/_orderItemId/_eventDateId" */))
const _478b7e88 = () => interopDefault(import('../pages/media/_id/_eventDateId.vue' /* webpackChunkName: "pages/media/_id/_eventDateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _7a4f3206,
    name: "403___de"
  }, {
    path: "/adminDashboard",
    component: _54a909ec,
    name: "adminDashboard___de"
  }, {
    path: "/affiliate",
    component: _d4d8b8c8,
    name: "affiliate___de"
  }, {
    path: "/dashboard",
    component: _f9bf47ba,
    name: "dashboard___de"
  }, {
    path: "/en",
    component: _c6225244,
    name: "index___en"
  }, {
    path: "/events",
    component: _6755c400,
    name: "events___de"
  }, {
    path: "/files",
    component: _3023c646,
    name: "files___de"
  }, {
    path: "/forgotPassword",
    component: _791eed85,
    name: "forgotPassword___de"
  }, {
    path: "/help",
    component: _15d54d28,
    name: "help___de"
  }, {
    path: "/ledger",
    component: _e0007360,
    name: "ledger___de"
  }, {
    path: "/locationOrganizer",
    component: _5cc30509,
    name: "locationOrganizer___de"
  }, {
    path: "/login",
    component: _243af4b8,
    name: "login___de"
  }, {
    path: "/logout",
    component: _8d6c65a4,
    name: "logout___de"
  }, {
    path: "/media",
    component: _50465773,
    name: "media___de"
  }, {
    path: "/orders",
    component: _3e845e68,
    name: "orders___de"
  }, {
    path: "/reports",
    component: _5d3152e4,
    name: "reports___de"
  }, {
    path: "/scan",
    component: _6eeb6964,
    name: "scan___de"
  }, {
    path: "/wizard",
    component: _8b9a785c,
    name: "wizard___de"
  }, {
    path: "/account/settings",
    component: _7f832cac,
    name: "account-settings___de"
  }, {
    path: "/adminDashboard/custom-invoice",
    component: _b4c204b2,
    name: "adminDashboard-custom-invoice___de"
  }, {
    path: "/adminDashboard/email",
    component: _032db7b6,
    name: "adminDashboard-email___de"
  }, {
    path: "/adminDashboard/flex-credit-memos",
    component: _1f7bd693,
    name: "adminDashboard-flex-credit-memos___de"
  }, {
    path: "/adminDashboard/payout",
    component: _07da5408,
    name: "adminDashboard-payout___de"
  }, {
    path: "/adminDashboard/presale-invoices",
    component: _db250582,
    name: "adminDashboard-presale-invoices___de"
  }, {
    path: "/adminDashboard/rolesAndPermissions",
    component: _de646838,
    name: "adminDashboard-rolesAndPermissions___de"
  }, {
    path: "/dashboard/editor",
    component: _5a3e52ec,
    name: "dashboard-editor___de"
  }, {
    path: "/dashboard/inProgress",
    component: _3d3d59f1,
    name: "dashboard-inProgress___de"
  }, {
    path: "/dashboard/inProgress1",
    component: _6a777610,
    name: "dashboard-inProgress1___de"
  }, {
    path: "/dashboard/inProgress2",
    component: _6a858d91,
    name: "dashboard-inProgress2___de"
  }, {
    path: "/en/403",
    component: _7a4f3206,
    name: "403___en"
  }, {
    path: "/en/adminDashboard",
    component: _54a909ec,
    name: "adminDashboard___en"
  }, {
    path: "/en/affiliate",
    component: _d4d8b8c8,
    name: "affiliate___en"
  }, {
    path: "/en/dashboard",
    component: _f9bf47ba,
    name: "dashboard___en"
  }, {
    path: "/en/events",
    component: _6755c400,
    name: "events___en"
  }, {
    path: "/en/files",
    component: _3023c646,
    name: "files___en"
  }, {
    path: "/en/forgotPassword",
    component: _791eed85,
    name: "forgotPassword___en"
  }, {
    path: "/en/help",
    component: _15d54d28,
    name: "help___en"
  }, {
    path: "/en/ledger",
    component: _e0007360,
    name: "ledger___en"
  }, {
    path: "/en/locationOrganizer",
    component: _5cc30509,
    name: "locationOrganizer___en"
  }, {
    path: "/en/login",
    component: _243af4b8,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _8d6c65a4,
    name: "logout___en"
  }, {
    path: "/en/media",
    component: _50465773,
    name: "media___en"
  }, {
    path: "/en/orders",
    component: _3e845e68,
    name: "orders___en"
  }, {
    path: "/en/reports",
    component: _5d3152e4,
    name: "reports___en"
  }, {
    path: "/en/scan",
    component: _6eeb6964,
    name: "scan___en"
  }, {
    path: "/en/wizard",
    component: _8b9a785c,
    name: "wizard___en"
  }, {
    path: "/events/archive",
    component: _3934d070,
    name: "events-archive___de"
  }, {
    path: "/events/create",
    component: _33fa2684,
    name: "events-create___de"
  }, {
    path: "/events/current",
    component: _1e3c79a7,
    name: "events-current___de"
  }, {
    path: "/events/history",
    component: _0f619b42,
    name: "events-history___de"
  }, {
    path: "/events/media",
    component: _87a185d6,
    name: "events-media___de"
  }, {
    path: "/forgotPassword/sent",
    component: _b9944896,
    name: "forgotPassword-sent___de"
  }, {
    path: "/login/create",
    component: _ea5025f4,
    name: "login-create___de"
  }, {
    path: "/media/categories",
    component: _dd9dd96a,
    name: "media-categories___de"
  }, {
    path: "/media/files",
    component: _32fe8e98,
    name: "media-files___de"
  }, {
    path: "/orders/all",
    component: _6aab8e44,
    name: "orders-all___de"
  }, {
    path: "/subscriptions/all",
    component: _4b07f397,
    name: "subscriptions-all___de"
  }, {
    path: "/ticket/create",
    component: _3f7a862a,
    name: "ticket-create___de"
  }, {
    path: "/en/account/settings",
    component: _7f832cac,
    name: "account-settings___en"
  }, {
    path: "/en/adminDashboard/custom-invoice",
    component: _b4c204b2,
    name: "adminDashboard-custom-invoice___en"
  }, {
    path: "/en/adminDashboard/email",
    component: _032db7b6,
    name: "adminDashboard-email___en"
  }, {
    path: "/en/adminDashboard/flex-credit-memos",
    component: _1f7bd693,
    name: "adminDashboard-flex-credit-memos___en"
  }, {
    path: "/en/adminDashboard/payout",
    component: _07da5408,
    name: "adminDashboard-payout___en"
  }, {
    path: "/en/adminDashboard/presale-invoices",
    component: _db250582,
    name: "adminDashboard-presale-invoices___en"
  }, {
    path: "/en/adminDashboard/rolesAndPermissions",
    component: _de646838,
    name: "adminDashboard-rolesAndPermissions___en"
  }, {
    path: "/en/dashboard/editor",
    component: _5a3e52ec,
    name: "dashboard-editor___en"
  }, {
    path: "/en/dashboard/inProgress",
    component: _3d3d59f1,
    name: "dashboard-inProgress___en"
  }, {
    path: "/en/dashboard/inProgress1",
    component: _6a777610,
    name: "dashboard-inProgress1___en"
  }, {
    path: "/en/dashboard/inProgress2",
    component: _6a858d91,
    name: "dashboard-inProgress2___en"
  }, {
    path: "/en/events/archive",
    component: _3934d070,
    name: "events-archive___en"
  }, {
    path: "/en/events/create",
    component: _33fa2684,
    name: "events-create___en"
  }, {
    path: "/en/events/current",
    component: _1e3c79a7,
    name: "events-current___en"
  }, {
    path: "/en/events/history",
    component: _0f619b42,
    name: "events-history___en"
  }, {
    path: "/en/events/media",
    component: _87a185d6,
    name: "events-media___en"
  }, {
    path: "/en/forgotPassword/sent",
    component: _b9944896,
    name: "forgotPassword-sent___en"
  }, {
    path: "/en/login/create",
    component: _ea5025f4,
    name: "login-create___en"
  }, {
    path: "/en/media/categories",
    component: _dd9dd96a,
    name: "media-categories___en"
  }, {
    path: "/en/media/files",
    component: _32fe8e98,
    name: "media-files___en"
  }, {
    path: "/en/orders/all",
    component: _6aab8e44,
    name: "orders-all___en"
  }, {
    path: "/en/subscriptions/all",
    component: _4b07f397,
    name: "subscriptions-all___en"
  }, {
    path: "/en/ticket/create",
    component: _3f7a862a,
    name: "ticket-create___en"
  }, {
    path: "/events/media/editor",
    component: _2015833d,
    name: "events-media-editor___de"
  }, {
    path: "/orders/events/upcomming",
    component: _6ad8654a,
    name: "orders-events-upcomming___de"
  }, {
    path: "/subscriptions/events/upcomming",
    component: _43ea4082,
    name: "subscriptions-events-upcomming___de"
  }, {
    path: "/en/events/media/editor",
    component: _2015833d,
    name: "events-media-editor___en"
  }, {
    path: "/en/orders/events/upcomming",
    component: _6ad8654a,
    name: "orders-events-upcomming___en"
  }, {
    path: "/en/subscriptions/events/upcomming",
    component: _43ea4082,
    name: "subscriptions-events-upcomming___en"
  }, {
    path: "/en/subscriptions/view/cancel/:id?",
    component: _2d15b942,
    name: "subscriptions-view-cancel-id___en"
  }, {
    path: "/en/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _492b8630,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___en"
  }, {
    path: "/en/events/edit/:id?",
    component: _54900cf7,
    name: "events-edit-id___en"
  }, {
    path: "/en/events/view/:id?",
    component: _a909e2dc,
    name: "events-view-id___en"
  }, {
    path: "/en/login/public/:token?",
    component: _6825cb74,
    name: "login-public-token___en"
  }, {
    path: "/en/orders/invoices/:id?",
    component: _c30adef2,
    name: "orders-invoices-id___en"
  }, {
    path: "/en/orders/payment/:id?",
    component: _2058576e,
    name: "orders-payment-id___en"
  }, {
    path: "/en/orders/view/:id?",
    component: _a14c4f74,
    name: "orders-view-id___en"
  }, {
    path: "/en/subscriptions/invoices/:id?",
    component: _8c42a1a4,
    name: "subscriptions-invoices-id___en"
  }, {
    path: "/en/subscriptions/item/:id?",
    component: _bcfce9c4,
    name: "subscriptions-item-id___en"
  }, {
    path: "/en/subscriptions/payment/:id?",
    component: _5c5f6db2,
    name: "subscriptions-payment-id___en"
  }, {
    path: "/en/subscriptions/view/:id?",
    component: _716d626d,
    name: "subscriptions-view-id___en"
  }, {
    path: "/subscriptions/view/cancel/:id?",
    component: _2d15b942,
    name: "subscriptions-view-cancel-id___de"
  }, {
    path: "/en/account/settings/:field/:token?",
    component: _7a6155eb,
    name: "account-settings-field-token___en"
  }, {
    path: "/en/customer/events/:eventId?/:orderItemId?",
    component: _57203286,
    name: "customer-events-eventId-orderItemId___en"
  }, {
    path: "/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _492b8630,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___de"
  }, {
    path: "/en/orders/payment/:id?/:payment_method/:status?",
    component: _da0ffd12,
    name: "orders-payment-id-payment_method-status___en"
  }, {
    path: "/en/subscriptions/payment/:id?/:payment_method/:status?",
    component: _7ad656f0,
    name: "subscriptions-payment-id-payment_method-status___en"
  }, {
    path: "/en/autologin/:id?",
    component: _2d9afc5e,
    name: "autologin-id___en"
  }, {
    path: "/en/check-in/:locationId?",
    component: _61ef696e,
    name: "check-in-locationId___en"
  }, {
    path: "/en/files/:orderItemId",
    component: _8482e988,
    name: "files-orderItemId___en"
  }, {
    path: "/en/Link/:hash?",
    component: _fe311b48,
    name: "Link-hash___en"
  }, {
    path: "/en/locationOrganizer/:locationId",
    component: _3c1aa1c9,
    name: "locationOrganizer-locationId___en"
  }, {
    path: "/en/login/:token",
    component: _2e298bf8,
    name: "login-token___en"
  }, {
    path: "/en/media/:id",
    component: _047080de,
    name: "media-id___en"
  }, {
    path: "/en/ticket/:customerMembershipId?",
    component: _6bfade62,
    name: "ticket-customerMembershipId___en"
  }, {
    path: "/events/edit/:id?",
    component: _54900cf7,
    name: "events-edit-id___de"
  }, {
    path: "/events/view/:id?",
    component: _a909e2dc,
    name: "events-view-id___de"
  }, {
    path: "/login/public/:token?",
    component: _6825cb74,
    name: "login-public-token___de"
  }, {
    path: "/orders/invoices/:id?",
    component: _c30adef2,
    name: "orders-invoices-id___de"
  }, {
    path: "/orders/payment/:id?",
    component: _2058576e,
    name: "orders-payment-id___de"
  }, {
    path: "/orders/view/:id?",
    component: _a14c4f74,
    name: "orders-view-id___de"
  }, {
    path: "/subscriptions/invoices/:id?",
    component: _8c42a1a4,
    name: "subscriptions-invoices-id___de"
  }, {
    path: "/subscriptions/item/:id?",
    component: _bcfce9c4,
    name: "subscriptions-item-id___de"
  }, {
    path: "/subscriptions/payment/:id?",
    component: _5c5f6db2,
    name: "subscriptions-payment-id___de"
  }, {
    path: "/subscriptions/view/:id?",
    component: _716d626d,
    name: "subscriptions-view-id___de"
  }, {
    path: "/en/locationOrganizer/:locationId/all",
    component: _cde7794a,
    name: "locationOrganizer-locationId-all___en"
  }, {
    path: "/en/locationOrganizer/:locationId/ticket-stats",
    component: _19ad373a,
    name: "locationOrganizer-locationId-ticket-stats___en"
  }, {
    path: "/en/scan/:hash/public",
    component: _c1dcb4ae,
    name: "scan-hash-public___en"
  }, {
    path: "/en/locationOrganizer/:locationId/event/:eventId?",
    component: _792d4084,
    name: "locationOrganizer-locationId-event-eventId___en"
  }, {
    path: "/account/settings/:field/:token?",
    component: _7a6155eb,
    name: "account-settings-field-token___de"
  }, {
    path: "/customer/events/:eventId?/:orderItemId?",
    component: _57203286,
    name: "customer-events-eventId-orderItemId___de"
  }, {
    path: "/en/files/:orderItemId/:eventDateId",
    component: _0aecae6a,
    name: "files-orderItemId-eventDateId___en"
  }, {
    path: "/en/media/:id/:eventDateId",
    component: _478b7e88,
    name: "media-id-eventDateId___en"
  }, {
    path: "/orders/payment/:id?/:payment_method/:status?",
    component: _da0ffd12,
    name: "orders-payment-id-payment_method-status___de"
  }, {
    path: "/subscriptions/payment/:id?/:payment_method/:status?",
    component: _7ad656f0,
    name: "subscriptions-payment-id-payment_method-status___de"
  }, {
    path: "/autologin/:id?",
    component: _2d9afc5e,
    name: "autologin-id___de"
  }, {
    path: "/check-in/:locationId?",
    component: _61ef696e,
    name: "check-in-locationId___de"
  }, {
    path: "/files/:orderItemId",
    component: _8482e988,
    name: "files-orderItemId___de"
  }, {
    path: "/Link/:hash?",
    component: _fe311b48,
    name: "Link-hash___de"
  }, {
    path: "/locationOrganizer/:locationId",
    component: _3c1aa1c9,
    name: "locationOrganizer-locationId___de"
  }, {
    path: "/login/:token",
    component: _2e298bf8,
    name: "login-token___de"
  }, {
    path: "/media/:id",
    component: _047080de,
    name: "media-id___de"
  }, {
    path: "/ticket/:customerMembershipId?",
    component: _6bfade62,
    name: "ticket-customerMembershipId___de"
  }, {
    path: "/locationOrganizer/:locationId/all",
    component: _cde7794a,
    name: "locationOrganizer-locationId-all___de"
  }, {
    path: "/locationOrganizer/:locationId/ticket-stats",
    component: _19ad373a,
    name: "locationOrganizer-locationId-ticket-stats___de"
  }, {
    path: "/scan/:hash/public",
    component: _c1dcb4ae,
    name: "scan-hash-public___de"
  }, {
    path: "/locationOrganizer/:locationId/event/:eventId?",
    component: _792d4084,
    name: "locationOrganizer-locationId-event-eventId___de"
  }, {
    path: "/files/:orderItemId/:eventDateId",
    component: _0aecae6a,
    name: "files-orderItemId-eventDateId___de"
  }, {
    path: "/media/:id/:eventDateId",
    component: _478b7e88,
    name: "media-id-eventDateId___de"
  }, {
    path: "/",
    component: _c6225244,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
